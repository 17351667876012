import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/irDyIq0M7Mk">
    <SEO title="Stages and Seasons - Jesus in Genesis" />
  </Layout>
)

export default SermonPost
